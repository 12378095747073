@import 'utilities/pxtorem';

//------------------------------------------
// Generic variables.
//------------------------------------------

// Theme colours.
$primary-hue: 143;
$primary-saturation: 42%;
$secondary-hue: 227;
$secondary-saturation: 9%;
$tertiary-hue: 345;
$tertiary-saturation: 100%;
$quadiary-hue: 0;
$quadiary-saturation: 0%;

// Breakpoints.
$bp-xs: 360px;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1600px;
$bp-xxxl: 1921px;

:root {
  --font-family-primary: 'Noto Sans', sans-serif;
  --font-family-secondary: 'Contrail One', sans-serif;
  --font-weight-strong: 700;
  --font-weight-normal: 400;

  // Colours.
  --colour-1: hsl(#{$primary-hue}, #{$primary-saturation}, 36%);
  --colour-2: hsl(#{$secondary-hue}, #{$secondary-saturation}, 20%);
  --colour-3: hsl(#{$tertiary-hue}, #{$tertiary-saturation}, 39%);
  --colour-4: hsl(#{$quadiary-hue}, #{$quadiary-saturation}, 100%);
  --colour-5: hsl(#{$quadiary-hue}, #{$quadiary-saturation}, 0%);

  // Colour variations.
  --colour-1-alt-1: hsl(#{$primary-hue}, #{$primary-saturation}, 31%);
  --colour-1-alt-2: hsl(#{$primary-hue}, #{$primary-saturation}, 26%);
  --colour-2-alt-1: hsl(#{$secondary-hue}, #{$secondary-saturation}, 10%);

  // Transparent colours.
  --colour-1-transparent:
    hsl(#{$primary-hue},
    #{$primary-saturation},
    36%,
    95%);
  --colour-1-transparent-1:
    hsl(#{$primary-hue},
    #{$primary-saturation},
    36%,
    50%);
  --colour-1-transparent-2:
    hsl(#{$primary-hue},
    #{$primary-saturation},
    36%,
    25%);
  --colour-4-transparent-1:
    hsl(#{$primary-hue},
    #{$primary-saturation},
    100%,
    20%);

  // Text colours.
  --primary-text-colour: var(--colour-2);
  --secondary-text-colour: var(--colour-4);
  --tertiary-text-colour: var(--colour-5);

  // Borders
  --bdr-primary: var(--colour-2);
  --bdr-secondary: var(--colour-4);

  // Vertical rythm.
  --vr: #{pxtorem(16)};
  --vr-xxs: calc(var(--vr) / 4);
  --vr-xs: calc(var(--vr) / 2);
  --vr-sm: var(--vr);
  --vr-md: calc(var(--vr) * 2);
  --vr-lg: calc(var(--vr) * 3);
  --vr-xl: calc(var(--vr) * 4);
  --vr-xxl: calc(var(--vr) * 5);
  --vr-sl: calc(var(--vr) * 6);

  // Animations
  --animation-tagline-delay: 5s;
  --animation-name-delay: 3.5s;
  --animation-location-delay: 5s;

  // z-index.
  --z-overlay: 900;
  --z-header: 800;
  --z-footer: 700;
  --z-default: 1;
  --z-below: -1;
  --z-bottomless-pit: -9000;
}
