@import 'variables';
@import 'utilities/pxtorem';

.divider {
  display: flex;
  flex-direction: column;
  align-items: center;

  &::after {
    content: '';
    margin-top: var(--vr-md);
    margin-bottom: var(--vr-md);
    width: 70%;
    border-bottom: 1px solid rgba(#000, 0.2);
    box-shadow: 0 1px rgba(#fff, 0.05);

    @media (min-width: $bp-sm) {
      content: none;
    }
  }

  &:last-of-type {
    &::after {
      content: none;
    }
  }
}
