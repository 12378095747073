@import 'variables';
@import 'utilities/pxtorem';

.label {
  color: var(--colour-4);
  padding: var(--vr-xxs);
  border-radius: 3px;
  font-size: pxtorem(12);
  line-height: 1.2;

  @media (min-width: $bp-xxxl) {
    font-size: pxtorem(14);
  }
}

.isPrimary {
  background: var(--colour-1);
}

.isSecondary {
  background: var(--colour-3);
}

.isTertiary {
  border: 1px solid var(--colour-4-transparent-1);
}

.isLarge {
  padding: var(--vr-xs);
  font-size: pxtorem(14);

  @media (min-width: $bp-xxxl) {
    font-size: pxtorem(18);
  }
}
