@import 'variables';

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.background {
  composes: primary from '~scss/helpers/backgrounds.scss';
}
