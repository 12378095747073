@import 'variables';
@import 'utilities/pxtorem';

.gotoTop {
  display: flex;
  justify-content: center;
  position: fixed;
  right: pxtorem(-70);
  bottom: pxtorem(22);
  background: var(--colour-1);
  box-shadow: 0 0 10px -1px var(--colour-2);
  border-radius: 50%;
  transition: all 0.3s;
  width: pxtorem(50);
  height: pxtorem(50);
  color: var(--secondary-text-colour);

  @media (min-width: $bp-sm) {
    bottom: pxtorem(30);
  }

  &:hover {
    background: var(--colour-1-alt-2);
  }
}

.show {
  right: pxtorem(10);

  @media (min-width: $bp-sm) {
    right: pxtorem(30);
  }
}
