.img {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.fullscreen {
  height: 100%;
}
