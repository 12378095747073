@import 'variables';
@import 'utilities/pxtorem';

.icon {
  font-size: pxtorem(80);
  text-shadow: -1px -1px var(--colour-1-alt-2);
  color: var(--secondary-text-colour);

  &Md {
    font-size: pxtorem(65);
  }

  &Sm {
    font-size: pxtorem(48);
  }

  &Xs {
    font-size: pxtorem(30);
  }

  &Padded {
    margin-bottom: var(--vr);
  }
}
