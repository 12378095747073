@import 'variables';
@import 'utilities/pxtorem';

.section {
  padding-top: var(--vr-xxl);
  padding-bottom: var(--vr-xxl);

  @media (min-width: $bp-sm) {
    padding-top: var(--vr-sl);
    padding-bottom: var(--vr-sl);
  }
}

.container {
  width: 100%;
  padding-left: var(--vr);
  padding-right: var(--vr);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $bp-sm) {
    max-width: 540px;
  }

  @media (min-width: $bp-md) {
    max-width: 720px;
  }

  @media (min-width: $bp-lg) {
    max-width: 960px;
  }

  @media (min-width: $bp-xl) {
    max-width: 1140px;
  }

  &Small {
    @media (min-width: $bp-xl) {
      width: 60%;
      max-width: 60%;
    }
  }

  &Medium {
    @media (min-width: $bp-xl) {
      width: 85%;
      max-width: 85%;
    }
  }

  &Large {
    @media (min-width: $bp-xl) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.bgPrimary {
  composes: primary from '~scss/helpers/backgrounds.scss';
}

.bgSecondary {
  composes: secondary from '~scss/helpers/backgrounds.scss';
}

.bgTertiary {
  composes: tertiary from '~scss/helpers/backgrounds.scss';
}
