@import 'variables';
@import 'utilities/pxtorem';
@import 'utilities/flex-gap';

.lighterNote {
  display: grid;
  color: var(--secondary-text-colour);

  @media (min-width: $bp-sm) {
    grid-gap: var(--vr-lg);
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $bp-lg) {
    grid-gap: var(--vr-md);
    grid-template-columns: repeat(4, 1fr);
  }
}

.container {
  composes: divider from '~scss/helpers/divider.scss';
}

.item {
  @include flex-gap(--vr);

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-self: center;
}

.title {
  font-size: pxtorem(42);
  line-height: 0.8;
  margin-bottom: var(--vr-xxs);
}

.content {
  width: 100%;

  @media (min-width: $bp-sm) {
    margin-left: var(--vr-xs);
  }
}

.icon {
  display: flex;
  justify-content: center;
}

.description {
  font-size: pxtorem(18);
  margin: 0;
}
