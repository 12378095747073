@import 'variables';
@import 'utilities/pxtorem';

.heading {
  text-align: center;

  &Primary {
    color: var(--primary-text-colour);
  }

  &Secondary {
    color: var(--secondary-text-colour);
  }

  &Tertiary {
    color: var(--secondary-text-colour);
  }
}

.title {
  font-family: var(--font-family-secondary);
  font-size: pxtorem(40);
  line-height: 1.2;
  letter-spacing: pxtorem(2);
  margin-bottom: var(--vr-md);

  @media (min-width: $bp-sm) {
    font-size: pxtorem(52);
    line-height: 1.33;
  }
}

.description {
  margin-bottom: var(--vr-lg);

  @media (min-width: $bp-sm) {
    margin-bottom: var(--vr-xxl);
  }
}
