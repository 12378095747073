@import 'utilities/pxtorem';

:root {
  --icon-background: hsl(240, 3%, 89%);
  --icon-tab: hsl(240, 3%, 80%);
  --icon-colour-primary: hsl(3, 100%, 67%);
  --icon-colour-primary-shadow: hsl(3, 100%, 42%);
  --icon-colour-secondary: hsl(41, 100%, 59%);
  --icon-colour-secondary-shadow: hsl(41, 100%, 34%);
  --icon-colour-tertiary: hsl(130, 67%, 47%);
  --icon-colour-tertiary-shadow: hsl(130, 67%, 22%);
  --tab-colour: hsl(0, 0%, 35%);
}

.bar {
  display: flex;
  background: var(--icon-background);
  border-bottom: 1px solid var(--icon-tab);
  overflow: hidden;
  height: var(--vr-md);
}

.icons {
  display: flex;
  padding-left: var(--vr-xs);
  padding-right: var(--vr-xs);
  align-items: center;
}

.icon {
  width: pxtorem(10);
  height: pxtorem(10);
  border-radius: 50%;
  margin-right: var(--vr-xs);

  &:first-of-type {
    background: var(--icon-colour-primary);
    box-shadow: inset 0 0 1px var(--icon-colour-primary-shadow);
  }

  &:nth-of-type(2) {
    background: var(--icon-colour-secondary);
    box-shadow: inset 0 0 1px var(--icon-colour-secondary-shadow);
  }

  &:last-of-type {
    background: var(--icon-colour-tertiary);
    box-shadow: inset 0 0 1px var(--icon-colour-tertiary-shadow);
  }
}

.tab {
  align-self: flex-end;
  position: relative;
  background: var(--icon-background);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-left: var(--vr-xs);
  padding-right: var(--vr-xs);
  font-size: pxtorem(12);
  border: 1px solid var(--icon-tab);
  border-bottom: 0;
  line-height: 2.2;
  color: var(--tab-colour);
  backface-visibility: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: pxtorem(16);
    width: pxtorem(16);
  }

  &::before {
    left: pxtorem(-16);
    border-right: 1px solid var(--icon-tab);
    border-bottom-right-radius: 8px;
    box-shadow: 5px 5px 0 var(--icon-background);
  }

  &::after {
    right: pxtorem(-16);
    border-bottom-left-radius: 8px;
    border-left: 1px solid var(--icon-tab);
    box-shadow: -5px 5px 0 var(--icon-background);
  }
}
