@import 'variables';

.link {
  color: inherit;
  border-bottom: 1px solid var(--bdr-primary);

  &:hover {
    color: inherit;
    border: 0;
  }
}

.alt {
  composes: link;
  border-color: var(--bdr-secondary);
}
