@mixin flex-gap($gap) {
  gap: var(#{$gap});

  &:not(.flexGap) {
    margin-left: calc(var(#{$gap}) * -2);
    margin-right: calc(var(#{$gap}) * -2);

    > * {
      margin-top: 0;
      margin-bottom: calc(var(#{$gap}));
      margin-right: calc(var(#{$gap}) / 2);
      margin-left: calc(var(#{$gap}) / 2);
    }
  }
}
