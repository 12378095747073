@import 'variables';
@import 'utilities/pxtorem';

.btn {
  font-size: pxtorem(14);
  line-height: 1;
  color: var(--colour-4);
  padding: pxtorem(8);
  border-radius: 7px;
  border: 1px solid var(--bdr-secondary);
  transition: all 0.3s;

  &:hover {
    background: var(--colour-1-alt-1);
  }

  & + & {
    margin-left: var(--vr);
  }

  @media (min-width: $bp-xs) {
    font-size: pxtorem(16);
    padding: pxtorem(10);
  }

  @media (min-width: $bp-sm) {
    font-size: pxtorem(18);
  }

  @media (min-width: $bp-xxxl) {
    font-size: pxtorem(24);
    padding: var(--vr-sm);
  }
}
