@import 'variables';
@import 'utilities/pxtorem';

.navigation {
  @media (min-width: $bp-lg) {
    position: sticky;
    top: 0;
    bottom: 0;
    height: pxtorem(60);
    background-color: rgba(#000, 0.7);
    z-index: var(--z-overlay);
  }

  &IsSticky {
    @media (min-width: $bp-lg) {
      box-shadow: inset 0 -1px var(--colour-1-alt-2), 0 1px 1px rgba(#000, 0.2);
      background-color: var(--colour-1-transparent);
    }
  }
}
