@import 'utilities/pxtorem';

.location {
  composes: hero shadow from '~scss/helpers/text.scss';
  font-size: pxtorem(24);
  letter-spacing: pxtorem(1);
  text-transform: uppercase;
  animation: location 0.5s ease-in-out;
  animation-delay: var(--animation-location-delay);
  animation-fill-mode: forwards;
}

@keyframes location {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
