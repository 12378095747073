@import 'variables';
@import 'utilities/pxtorem';
@import 'utilities/flex-gap';

.labels {
  @include flex-gap(--vr-xxs);

  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.isLarge {
  @include flex-gap(--vr-xs);
}

.isCentered {
  justify-content: center;

  @media (min-width: $bp-sm) {
    justify-content: unset;
  }
}
