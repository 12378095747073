@import 'utilities/pxtorem';

.tagline {
  composes: hero shadow from '~scss/helpers/text.scss';
  font-size: pxtorem(32);
  letter-spacing: pxtorem(1);
  text-transform: uppercase;
  overflow: hidden;
  animation: tagline 0.5s ease-in-out;
  animation-delay: var(--animation-tagline-delay);
  animation-fill-mode: forwards;
}

@keyframes tagline {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
