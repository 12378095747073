@import 'variables';
@import 'utilities/pxtorem';

.text {
  margin: 0;
  padding: var(--vr) 0;
}

.name {
  font-size: pxtorem(16);
  margin-bottom: 0;
}

.company {
  font-size: pxtorem(14);
}
