@import 'variables';

.hero {
  height: 100vh;
}

.img {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.05);
  }
}

.transition {
  &::before {
    animation: transition 2s ease;
  }
}

@keyframes transition {
  50% {
    background: var(--colour-1);
  }
}
