@import 'variables';

.primary {
  background-color: var(--colour-1);
  box-shadow: inset 0 1px var(--colour-1-alt-2), inset 0 -1px var(--colour-1-alt-2);
}

.secondary {
  background-color: var(--colour-2);
  box-shadow: inset 0 1px var(--colour-2-alt-1), inset 0 -1px var(--colour-2-alt-1);
}

.tertiary {
  background-color: #fff;
}
